import { Currency, ETHER as BNB, WETH, ChainId, Token } from '@levinswap/uniswap-sdk'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import bnbLogo from '../../assets/images/bnb-logo.webp'
import useHttpLocations from '../../hooks/useHttpLocations'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import Logo from '../Logo'
import store from '../../state'
import { BASE_DEX_LIST } from '../../constants/lists'

export const getTokenLogoURL = (address: string) => {
  const imageURL = store
    .getState()
    .lists.byUrl[BASE_DEX_LIST].current?.tokens.find(item => item.address.toLowerCase() === address.toLowerCase())
    ?.logoURI

  if (imageURL) return imageURL
  return `https://raw.githubusercontent.com/trustwallet/assets/master/dapps/xdai/assets/${address}/logo.png`
}

const StyledxDaiLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
`

export default function CurrencyLogo({
  currency,
  size = '24px',
  style
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
}) {
  const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)

  const srcs: string[] = useMemo(() => {
    if (currency === BNB || currency === WETH[ChainId.OPBNB]) return []

    if (currency instanceof Token) {
      if (currency instanceof WrappedTokenInfo) {
        return [...uriLocations, getTokenLogoURL(currency.address)]
      }

      return [getTokenLogoURL(currency.address)]
    }
    return []
  }, [currency, uriLocations])

  if (currency === BNB || currency === WETH[ChainId.OPBNB]) {
    return <StyledxDaiLogo src={bnbLogo} size={size} style={style} />
  }

  return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
}
